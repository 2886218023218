import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { useFormik } from "formik";
import useToastify from "../../hooks/ui/useToastify";
import IconMinus from "../../assets/icon_js/IconMinus";
import IconPlus from "../../assets/icon_js/IconPlus";

function CartFormAdjustItem({ cartType, item, handleQuantityChange }) {
  const { toastMessage } = useToastify();

  const formik = useFormik({
    initialValues: {
      quantity: item.quantity,
    },
  });

  useEffect(() => {
    formik.setFieldValue("quantity", item.quantity);
  }, [item]);

  return (
    <>
      <div className="bc-cart-item-quantity cart-col-qty cart-col-5">
        <div className="cart-item-inner">
          <p className="cart-item-lable">Quantity</p>
          <div className="text-center">
            <div className="form-increment">
              {cartType === "full" && (
                <button
                  className="button"
                  onClick={() =>
                    handleQuantityChange(
                      -1,
                      item["sku"],
                      toastMessage,
                      formik,
                      0
                    )
                  }
                  disabled={
                    item["quantity"] <= item["minPurchaseQuantity"] ||
                    item["quantity"] === 1
                  }
                >
                  <IconMinus />
                </button>
              )}
              <form>
                <input
                  type="number"
                  name="quantity"
                  id={item.sku}
                  onWheel={(e) => e.target.blur()}
                  className="form-input qty-input"
                  onChange={formik.handleChange}
                  onBlur={(e) =>
                    handleQuantityChange(
                      formik.values.quantity,
                      item["sku"],
                      toastMessage,
                      formik,
                      1
                    )
                  }
                  value={formik.values.quantity}
                />
              </form>
              {cartType === "full" && (
                <button
                  className="button"
                  onClick={() =>
                    handleQuantityChange(
                      1,
                      item["sku"],
                      toastMessage,
                      formik,
                      0
                    )
                  }
                  disabled={
                    (item["quantity"] >= item["maxPurchaseQuantity"] &&
                      item["maxPurchaseQuantity"] !== 0) ||
                    item["quantity"] >= item["inventory_level"]
                  }
                >
                  <IconPlus />
                </button>
              )}
            </div>
            <small
              className={
                item.inventory_level === 0
                  ? "bc-cart-item-error cart-invertory-count"
                  : "cart-invertory-count"
              }
            >{`${item.inventory_level} Left`}</small>
          </div>
        </div>
      </div>
    </>
  );
}

CartFormAdjustItem.propTypes = {
  item: PropTypes.object,
  cartType: PropTypes.string,
};

export default CartFormAdjustItem;
