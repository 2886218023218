import React, { useState } from "react";
import { cartFormToData } from "../../utils/cart";
import { formatPrice } from "../../utils/money";
import Button from "../form/button/Button";
import { productQtyMessages } from "../../utils/common";
import Image from "../common/Image";

export default function CartFormModal({
  show,
  onClose,
  duplicateUpcItems,
  onCloseDuplicateUpcModal,
  onChooseSku,
}) {
  // const [isOpen, toggleDialog] = useToggle(false);
  const [selectedId, setSelectedId] = useState();
  if (!show) {
    return null;
  }
  const handelClick = () => {
    onCloseDuplicateUpcModal();
  };
  const onRadioChange = (e) => {
    setSelectedId(e.target.value);
  };

  const onAddSelectedUpc = () => {
    onChooseSku(selectedId);
  };

  return (
    <>
      <div className="scanner-modal-wrapper">
        <div className="order-form-popup">
          <div className="">
            <section className="container-full">
              {duplicateUpcItems?.length ? (
                <>
                  <section className="bc-cart bc-cart-order-form">
                    <header className="bc-cart-header">
                      <div className="bc-cart-header__item cart-col-mark cart-col-1">
                        Item
                      </div>
                      {/* <div className="bc-cart-header__item cart-col-name cart-col-3"></div> */}
                      <div className="bc-cart-header__price cart-col-price cart-col-4">
                        Item Price
                      </div>
                    </header>

                    <div className="bc-cart-body">
                      {cartFormToData(duplicateUpcItems).map((item, index) => {
                        if (item) {
                          return (
                            <>
                              <div className="order-form-popup-item">
                                <input
                                  className="form-check-input"
                                  type="radio"
                                  value={item.id}
                                  name="LinkTarget"
                                  id={index}
                                  onChange={onRadioChange}
                                  // checked={index === 0}
                                />
                                <label
                                  htmlFor={index}
                                  className="form-check-label ms-0"
                                >
                                  <div
                                    className={
                                      item.inventory_level === 0
                                        ? "item-zero-inventory bc-cart-item "
                                        : item.quantity <
                                            item.minPurchaseQuantity &&
                                          item.minPurchaseQuantity !== 0
                                        ? "item-less-minpurchaseqty bc-cart-item"
                                        : item.quantity >
                                            item.maxPurchaseQuantity &&
                                          item.maxPurchaseQuantity !== 0
                                        ? `item-less-minpurchaseqty bc-cart-item`
                                        : item.inventory_level < item.quantity
                                        ? "item-less-inventory bc-cart-item "
                                        : "bc-cart-item "
                                    }
                                    key={item.id}
                                  >
                                    <div className="cart-item-mark cart-col-mark cart-col-1">
                                      <span className="form-label form-label-facet">
                                        <span className="checkbox-icon"></span>
                                      </span>
                                    </div>

                                    <div className="bc-cart-item-image cart-col-image cart-col-2">
                                      <div className="cart-img-inner">
                                        <Image
                                          src={item.image_url}
                                          alt={item.name}
                                        />
                                      </div>
                                    </div>
                                    <div className="bc-cart-item-meta cart-col-name cart-col-3">
                                      <h3 className="bc-cart-item__product-title">
                                        {item.name}
                                      </h3>

                                      {/* <p className="cart-item-option-text"> */}

                                      {item.name_1 !== "" && (
                                        <p className="cart-item-option-text">
                                          <strong>
                                            {item.name_1.replace(
                                              "Choose Your ",
                                              ""
                                            )}
                                          </strong>
                                          <span>{item.value_1}</span>
                                        </p>
                                      )}

                                      {item.name_2 !== "" && (
                                        <p className="cart-item-option-text">
                                          <strong>
                                            {item.name_2.replace(
                                              "Choose Your ",
                                              ""
                                            )}
                                          </strong>
                                          <span>{item.value_2}</span>
                                        </p>
                                      )}

                                      <p className="cart-item-option-text">
                                        <strong className="">{`SKU - ${item.sku}`}</strong>
                                      </p>

                                      {item.inventory_level === 0 && (
                                        <div className="bc-cart-item-less-quantity-error">
                                          <p>{productQtyMessages("zero", 0)}</p>
                                        </div>
                                      )}

                                      {item.inventory_level < item.quantity &&
                                        item.inventory_level !== 0 && (
                                          <div className="bc-cart-item-less-quantity-error">
                                            <p>
                                              {productQtyMessages(
                                                "less-qty",
                                                0
                                              )}
                                            </p>
                                          </div>
                                        )}

                                      {item.inventory_level !== 0 &&
                                        item.minPurchaseQuantity !== 0 && (
                                          <div className="bc-cart-item-less-quantity-error">
                                            <p>
                                              {productQtyMessages(
                                                "minimum",
                                                item.minPurchaseQuantity
                                              )}
                                            </p>
                                          </div>
                                        )}

                                      {item.inventory_level !== 0 &&
                                        item.maxPurchaseQuantity !== 0 && (
                                          <div className="bc-cart-item-less-quantity-error">
                                            <p>
                                              {productQtyMessages(
                                                "maximum",
                                                item.maxPurchaseQuantity
                                              )}
                                            </p>
                                          </div>
                                        )}
                                    </div>

                                    <div className="bc-cart-item-single-price cart-col-price cart-col-4">
                                      <div className="cart-item-inner">
                                        <p className="cart-item-lable">Price</p>
                                        <div className="text-center">
                                          <div className="price-wrapper">
                                            <div className="cart-price-wrapper">
                                              {formatPrice(item.price)}
                                            </div>
                                          </div>
                                          <small
                                            className={
                                              item.inventory_level === 0
                                                ? "bc-cart-item-error cart-invertory-count"
                                                : "cart-invertory-count"
                                            }
                                          >{`${item.inventory_level} Left`}</small>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </label>
                              </div>
                            </>
                          );
                        }
                      })}
                    </div>
                  </section>
                </>
              ) : null}
            </section>
          </div>

          <div className="cart-footer-section">
            <div className="cart-bottom-action-section">
              <div className="bc-cart-actions flex flex-wrap align-right vertical-middle">
                <span
                  className="button button--secondary button-small"
                  onClick={handelClick}
                  role="button"
                  tabIndex={0}
                  onKeyDown={(e) => {
                    if (e.key === "Enter") {
                      handelClick();
                    }
                  }}
                >
                  Close
                </span>
                <Button
                  className="button button-small"
                  onClick={onAddSelectedUpc}
                  disabled={selectedId ? false : true}
                >
                  Add selected item
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
