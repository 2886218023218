import React from "react";
import Button from "../form/button/Button";
import { formatPrice } from "../../utils/money";
import Loader from "../form/Loader";
import { isBrowser } from "../../utils/common";
import useCustomerGroupRestriction from "../../hooks/customer/useCustomerGroupRestriction";

function CartFormFooter({
  cartTotal,
  onTriggerAddToCart,
  clearCartForm,
  removeSelectedItems,
  isLoading,
}) {
  let itemErrorFlag =
    isBrowser && parseInt(window.localStorage.getItem("itemError"));
  const { isCustomerRestricted } = useCustomerGroupRestriction();

  return (
    <div className="cart-footer-section">
      <div className="cart-bottom-action-section">
        <div className="bc-cart-actions flex flex-wrap align-right vertical-middle">
          <span
            className="button button--secondary button-small"
            onClick={() => clearCartForm(false)}
            role="button"
            tabIndex={0}
            onKeyDown={(e) => {
              if (e.key === "Enter") {
                clearCartForm();
              }
            }}
          >
            Reset Cart Form
          </span>
          <span
            className="button button-small"
            onClick={() => removeSelectedItems()}
            role="button"
            tabIndex={0}
            onKeyDown={(e) => {
              if (e.key === "Enter") {
                removeSelectedItems();
              }
            }}
          >
            Remove selected Item From Cart Form
          </span>
        </div>
      </div>
      <footer className="bc-cart-footer">
        <div className="cart-totals">
          <ul className="cart-total">
            <li>
              <div className="col">
                <strong>{`Grand Total:`}</strong>
              </div>
              <div className="col align-right">
                <strong className="big">{formatPrice(cartTotal)}</strong>
              </div>
            </li>
          </ul>
        </div>

        {!isCustomerRestricted ? (
          <div className="bc-cart-actions flex align-right vertical-middle">
            <Button
              className="add-to-cart button button-large text-uppercase button-addtocart"
              type="submit"
              onClick={() => onTriggerAddToCart()}
              disabled={itemErrorFlag ? true : false}
            >
              {isLoading ? <Loader /> : "Update Cart"}
            </Button>
          </div>
        ) : null}
      </footer>
    </div>
  );
}

export default CartFormFooter;
