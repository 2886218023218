import React, { useEffect, useState } from "react";
import Layout from "../layout/Layout";
import useCartForm from "../hooks/products/useCartForm";
import { formatPrice } from "../utils/money";
import CartFormAdjustItem from "../components/cart/CartFormAdjustItem";
import { cartFormToData } from "../utils/cart";
import CartFormFooter from "../components/cart/CartFormFooter";
import CartHeader from "../components/cart/CartHeader";
import Loader from "../components/form/Loader";
import { isBrowser } from "../utils/common";
import { Link } from "gatsby";
import { isUserLoggedIn } from "../utils/auth";
import Button from "../components/form/button/Button";
import CartFormModal from "../components/cart/CartFormModal";
import IconDownload from "../assets/icon_js/IconDownload";
import IconClose from "../assets/icon_js/IconClose";
import { productQtyMessages } from "../utils/common";
import ReactModal from "../components/form/Dialog/NewModal";
import Image from "../components/common/Image";
import { staticPage } from "../../config/staticPage";


function CartFormPage() {
  const [skus, setSKUs] = useState();
  const user = isUserLoggedIn();
  // const [isOpen, toggleDialog] = useToggle(false);

  const parseURL = (url) => {
    if (url) {
      if (url.includes(".com")) {
        return url.split(".com")[1];
      } else {
        return url;
      }
    }
  };
  let itemErrorFlag =
    isBrowser && parseInt(window.localStorage.getItem("itemError"));
  let itemErrorString =
    isBrowser && window.localStorage.getItem("itemErrorString")?.toString();
  // let missing_skus_string_test =
  //   isBrowser && window.localStorage.getItem("missing_skus_string").toString();

  const {
    cartTotal,
    lineItems,
    fileError,
    isLoading,
    isUsingInput,
    inputFiledValue,
    orderIds,
    inputFieldError,
    isDuplicateUpc,
    isUsingOrderId,
    duplicateUpcItems,
    aRef,
    missing_skus_string,
    isLoadingCart,
    handleFileChange,
    handleParse,
    removeItem,
    handleQuantityChange,
    onTriggerAddToCart,
    clearCartForm,
    register,
    removeSelectedItems,
    handleUserInput,
    handleEnterKey,
    handleSkuSubmit,
    onCloseDuplicateUpcModal,
    onChooseSku,
    handleInputTypeChange,
    clearMissingSkuMessage,
    downloadSampleExcel,
    loadCartData,    
    onClickClearCartForm
  } = useCartForm(skus, setSKUs);
  useEffect(async () => {
    loadCartData()    
  }, [])
  // console.log(localStorage.getItem("cartFormItems"), "local");      
  return (
    <>
      {user ? (
        <>
          <div className="page-wrapper">
            <div className="page-heading-section">
              <div className="container">
                <h1 className="page-title">Scanner</h1>
              </div>
            </div>
          </div>

          <div className="order-form-page">
            <div className="container">
              <div className="order-form-section">
                <div className="row flex flex-wrap">
                  <div className="col order-input-section">
                    <div className="scanner-switch-wrapper">
                      {isUsingOrderId ? (
                        <h6 className="">Enter OrderId</h6>
                      ) : (
                        <h6 className="">Enter SKU/UPC</h6>
                      )}

                      <div className="scanner-checkbox-switch">
                        <input
                          className={`form-checkbox ${
                            isUsingOrderId ? "bg-success" : ""
                          }`}
                          type="checkbox"
                          name="scanner-switch-id"
                          id="scanner-switch-id"
                          defaultChecked={isUsingOrderId ? true : false}
                          onChange={(e) =>
                            handleInputTypeChange(e.target.checked)
                          }
                        />

                        <label
                          className="form-label"
                          htmlFor="scanner-switch-id"
                        >
                          {isUsingOrderId
                            ? "Enter Using OrderId"
                            : "Enter Using OrderId"}
                        </label>
                      </div>
                    </div>
                    {orderIds !== "" && <p>Suggested order ids : {orderIds}</p>}
                    <div>
                      <form onSubmit={handleSkuSubmit}>
                        {" "}
                        {/* Use form onSubmit */}
                        <input
                          className={
                            inputFieldError !== ""
                              ? "form-label form-input error"
                              : "form-input form-label"
                          }
                          placeholder={
                            isUsingOrderId ? "Input OrderId" : "Input SKU/UPC"
                          }
                          onChange={handleUserInput}
                          onKeyDown={handleEnterKey}
                          value={inputFiledValue}
                          disabled={isLoading}
                        />
                        {inputFieldError !== "" && (
                          <p className="bc-cart-item-error">
                            {inputFieldError}
                          </p>
                        )}
                        <div
                          className="sku-submit-button"
                          style={{ textAlign: "center" }}
                        >
                          <button
                            className="button"
                            type="submit"
                            disabled={isLoading || inputFiledValue === ""}
                          >
                            Submit
                          </button>
                        </div>
                      </form>
                      <div>
                        {isDuplicateUpc === true &&
                        duplicateUpcItems?.length ? (
                          <ReactModal
                            isOpen={isDuplicateUpc}
                            setIsOpen={() => onCloseDuplicateUpcModal()}
                            title={"Choose Sku"}
                          >
                            <CartFormModal
                              show={isDuplicateUpc}
                              onClose={onCloseDuplicateUpcModal}
                              duplicateUpcItems={duplicateUpcItems}
                              onCloseDuplicateUpcModal={
                                onCloseDuplicateUpcModal
                              }
                              onChooseSku={onChooseSku}
                            />
                          </ReactModal>
                        ) : (
                          ""
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="col order-csv-section">
                    <h6 className="flex justify-space">
                      Upload Excel File
                      <span className="sample-download-link">
                        <a onClick={downloadSampleExcel}>
                          <i className="icon">
                            <IconDownload />
                          </i>
                          Download Sample Excel File
                        </a>
                      </span>
                    </h6>
                    <div className="csv-import-section">
                      <label className="form-label" htmlFor="csvInput">
                        <input
                          onChange={(e) => handleFileChange(e)}
                          id="csvInput"
                          name="file"
                          type="File"
                          className="csv-import-input"
                          ref={aRef}
                        />
                      </label>
                      <p className="bc-cart-item-error">
                        {fileError ? fileError : ""}
                      </p>

                      <div className="csv-import-button">
                        <button
                          className="button"
                          onClick={() => handleParse()}
                          disabled={(fileError === "") || (!isLoadingCart) ? false : true}
                        >
                          Import
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <section className="container-full">
                {isLoadingCart ? (
                  <div>
                    <Loader />
                    <h3>Syncing Cart...</h3>
                  </div>) 
                : (null)}
                {isLoading ? <Loader /> : null}                
                {missing_skus_string !== "" ? (
                  <div>
                    <h5 className="bc-alert bc-alert--error">
                      {isUsingInput &&
                      isUsingInput !== undefined &&
                      isUsingOrderId
                        ? missing_skus_string +
                          " sku(s) data not found from your given orderid."
                        : missing_skus_string +
                          " sku(s) data not found from your file."}
                      <button
                        className="bc-alert-close-button"
                        onClick={clearMissingSkuMessage}
                        type="button"
                      >
                        <IconClose />
                      </button>
                    </h5>
                  </div>
                ) : null}
                {lineItems?.length ? (
                  <>
                    {itemErrorFlag ? (
                      <div>
                        <h5 className="bc-alert bc-alert--error">
                          {itemErrorString || ""}
                        </h5>
                      </div>
                    ) : null}

                    <section className="bc-cart bc-cart-order-form">
                      <CartHeader />

                      <div className="bc-cart-body">
                        {cartFormToData(lineItems).map((item) => {
                          if (item) {
                            // console.log(item, "items");
                            return (
                              <div
                                className={
                                  item.inventory_level === 0
                                    ? "item-zero-inventory bc-cart-item "
                                    : item.quantity <
                                        item.minPurchaseQuantity &&
                                      item.minPurchaseQuantity !== 0
                                    ? "item-less-minpurchaseqty bc-cart-item"
                                    : item.quantity >
                                        item.maxPurchaseQuantity &&
                                      item.maxPurchaseQuantity !== 0
                                    ? `item-less-minpurchaseqty bc-cart-item`
                                    : item.inventory_level < item.quantity
                                    ? "item-less-inventory bc-cart-item "
                                    : "bc-cart-item "
                                }
                                key={item.id}
                              >
                                <div className="cart-item-mark cart-col-mark cart-col-1">
                                  <label className="form-label form-label-facet">
                                    <input
                                      type="checkbox"
                                      className="form-checkbox-facet"
                                      value={item.id}
                                      {...register("skus", [])}
                                    />
                                    <span className="checkbox-icon"></span>
                                  </label>
                                </div>
                                <div className="bc-cart-item-image cart-col-image cart-col-2">
                                  <div className="cart-img-inner">
                                    <Link to={`${parseURL(item.product_url)}`}>
                                      <Image
                                        src={item.image_url}
                                        alt={`${item.name}`}
                                      />
                                    </Link>
                                  </div>
                                </div>
                                <div className="bc-cart-item-meta cart-col-name cart-col-3">
                                  <h3 className="bc-cart-item__product-title">
                                    <Link to={`${parseURL(item.product_url)}`}>
                                      {item.name}
                                    </Link>
                                  </h3>

                                  {/* <p className="cart-item-option-text"> */}

                                  {item.name_1 !== "" && (
                                    <p className="cart-item-option-text">
                                      <strong>
                                        {item.name_1.replace(
                                          "Choose Your ",
                                          ""
                                        )}
                                      </strong>
                                      {/* <strong>:</strong> */}
                                      <span>{item.value_1}</span>
                                    </p>
                                  )}

                                  {item.name_2 !== "" && (
                                    <p className="cart-item-option-text">
                                      <strong>
                                        {item.name_2.replace(
                                          "Choose Your ",
                                          ""
                                        )}
                                      </strong>
                                      {/* <strong>:</strong> */}
                                      <span>{item.value_2}</span>
                                    </p>
                                  )}

                                  <p className="cart-item-option-text">
                                    <strong className="">{`SKU - ${item.sku}`}</strong>
                                  </p>

                                  {item.inventory_level === 0 && (
                                    <div className="bc-cart-item-less-quantity-error">
                                      <p>{productQtyMessages("zero", 0)}</p>
                                    </div>
                                  )}

                                  {item.inventory_level < item.quantity &&
                                    item.inventory_level !== 0 && (
                                      <div className="bc-cart-item-less-quantity-error">
                                        <p>
                                          {productQtyMessages("less-qty", 0)}
                                        </p>
                                      </div>
                                    )}

                                  {item.inventory_level !== 0 &&
                                    item.minPurchaseQuantity !== 0 && (
                                      <div className="bc-cart-item-less-quantity-error">
                                        <p>
                                          {productQtyMessages(
                                            "minimum",
                                            item.minPurchaseQuantity
                                          )}
                                        </p>
                                      </div>
                                    )}

                                  {item.inventory_level !== 0 &&
                                    item.maxPurchaseQuantity !== 0 && (
                                      <div className="bc-cart-item-less-quantity-error">
                                        <p>
                                          {productQtyMessages(
                                            "maximum",
                                            item.maxPurchaseQuantity
                                          )}
                                        </p>
                                      </div>
                                    )}
                                </div>

                                <div className="bc-cart-item-single-price cart-col-price cart-col-4">
                                  <div className="cart-item-inner">
                                    <p className="cart-item-lable">Price</p>
                                    <div>
                                      <div className="price-wrapper">
                                        <div className="cart-price-wrapper">
                                          {item.price !== 0
                                            ? formatPrice(item.price)
                                            : 0}
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>

                                <CartFormAdjustItem
                                  cartType={"full"}
                                  item={item}
                                  handleQuantityChange={handleQuantityChange}
                                />

                                <div className="bc-cart-item-total-price cart-col-tprice cart-col-6">
                                  <div className="cart-item-inner">
                                    <p className="cart-item-lable">
                                      Total Price
                                    </p>
                                    <div className="flex vertical-middle justify-space">
                                      <div className="cart-total-price">
                                        {item.totalPrice !== 0
                                          ? formatPrice(item.totalPrice)
                                          : 0}
                                      </div>
                                      <div className="cart-item-action">
                                        <button
                                          className="bc-link bc-cart-item__remove-button"
                                          onClick={() => removeItem(item.id)}
                                          type="button"
                                        >
                                          &nbsp;
                                        </button>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            );
                          }
                        })}
                        <CartFormFooter
                          cartTotal={cartTotal}
                          onTriggerAddToCart={onTriggerAddToCart}
                          clearCartForm={onClickClearCartForm}
                          removeSelectedItems={removeSelectedItems}
                          isLoading={isLoading}
                        />
                      </div>
                    </section>
                  </>
                ) : null}
              </section>
            </div>
          </div>
          {/* <section className="container" id="cart-container">
                <Cart cartType="full" />
              </section> */}
        </>
      ) : (
        <div className="bc-cart__empty">
          <p className="bc-cart__title--empty">
            Please Login to see the cart form
          </p>
          <Button to="/login">Login</Button>
        </div>
      )}
    </>
  );
}
export const Head = () => {
  let login_nav = staticPage;
  let loginDetails = null;
  for (const obj of login_nav) {
    if (obj.name === "scanner") {
      loginDetails = obj;
      break; // Exit the loop once the desired object is found.
    }
  }
  return (
    <>
      <title>{loginDetails.title}</title>
      <meta name="description" content={loginDetails.description} />
    </>
  );
};

CartFormPage.Layout = Layout
export default CartFormPage;
