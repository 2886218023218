import { formatPrice, getMinimumPrice } from './money'

// two dimentional grid varaints mapping...
export const getCurrentVariantData = (column, row, variants) => {
  for (let i = 0; i < variants.length; i++) {
    let obj = {}

    const options = variants[i]['options']
    let option_arr = [column, row]

    for (let j = 0; j < options.length; j++) {
      const opt = options[j]['values']
      option_arr = [...option_arr, ...opt]
    }

    const uniqueAddresses = Array.from(
      new Set(option_arr.map((a) => a.id))
    ).map((id) => {
      return option_arr.find((a) => a.id === id)
    })
    if (uniqueAddresses.length === 2) {
      option_arr = []
      obj = variants[i]
      return obj
    }
  }
}

// Single grid varaints mapping...
export const getSingleGridVariant = (column, variants) => {
  let obj = {}

  for (let i = 0; i < variants.length; i++) {
    const options = variants[i]['options']
    for (let j = 0; j < options.length; j++) {
      const opt = options[j]['values'][0]
      if (JSON.stringify(opt) === JSON.stringify(column)) {
        obj = variants[i]
      }
    }
  }

  return obj
}

const validateAndParsewithoutVaraints = (
  data,
  min_purchase_qty,
  max_purchase_qty,
  available_inventory,
  toastMessage
) => {
  let line_item = []
  for (const key in data) {
    const [product_id, variant_id, sku] = key.split(',')

    let isValid = true

    if (data[key] > available_inventory) {
      toastMessage(
        'warning',
        `Sorry, you can purchase at maximum ${available_inventory} of this item`
      )
      isValid = false
    }

    if (
      min_purchase_qty > 0 &&
      +data[key] !== 0 &&
      +data[key] < min_purchase_qty
    ) {
      toastMessage(
        'warning',
        `Sorry, you must purchase at least ${min_purchase_qty} of this item`
      )
      isValid = false
    }

    if (
      max_purchase_qty > 0 &&
      +data[key] !== 0 &&
      +data[key] > max_purchase_qty
    ) {
      toastMessage(
        'warning',
        `Sorry, you can only purchase ${max_purchase_qty} of this item`
      )
      isValid = false
    }

    if (isValid) {
      if (data[key] > 0) {
        line_item.push({
          product_id: +product_id,
          sku: sku,
          variant_id: +variant_id,
          quantity: data[key],
        })
      }
    } else {
      line_item = false
    }

    return line_item
  }
}

const parseProductWithVariants = (data) => {
  const line_items = []

  for (const key in data) {
    const [product_id, variant_id, sku] = key.split(',')

    if (data[key] > 0) {
      line_items.push({
        product_id: +product_id,
        sku: sku,
        variant_id: +variant_id,
        quantity: data[key],
      })
    }
  }

  return line_items
}

export const dataToLineItems = (
  values,
  min_purchase_qty,
  max_purchase_qty,
  total_available_inventory,
  toastMessage,
  is_variant_not_available
) => {
  let line_items = []
  if (is_variant_not_available) {
    line_items = validateAndParsewithoutVaraints(
      values,
      min_purchase_qty,
      max_purchase_qty,
      total_available_inventory,
      toastMessage
    )
  } else {
    line_items = parseProductWithVariants(values)
  }
  return line_items
}

export const calculateGridTotal = (data) => {
  let total = 0
  for (const key in data) {
    const [, , , price] = key.split(',')
    // Need to convert - to . for calculating price ...
    const item_price = parseFloat(price.toString().replace('-', '.'))
    if (data[key] > 0) {
      total = total + item_price * data[key]
    }
  }
  return formatPrice(total)
}

export const validateInputBox = (
  input_value,
  min_purchase_qty,
  max_purchase_qty,
  available_inventory,
  toastMessage,
  name,
  formik
) => {
  let isValid = true

  if (available_inventory === 0) {
    formik.setFieldValue(name, '')
    isValid = false
    return isValid
  }

  if (input_value && input_value < 0) {
    toastMessage('warning', `Sorry, you can't enter negative values`)
    formik.setFieldValue(name, '')
    isValid = false
    return isValid
  }

  if (input_value && parseInt(input_value) === 0) {
    toastMessage('warning', `Sorry, you must purchase at least 1 of this item`)
    formik.setFieldValue(name, '')
    isValid = false
    return isValid
  }

  if (input_value > available_inventory && available_inventory > 0) {
    toastMessage(
      'warning',
      `Sorry, you can purchase at maximum ${available_inventory} of this item`
    )
    formik.setFieldValue(name, available_inventory)
    isValid = false
  }

  if (
    min_purchase_qty > 0 &&
    +input_value !== 0 &&
    +input_value < min_purchase_qty
  ) {
    toastMessage(
      'warning',
      `Sorry, you must purchase at least ${min_purchase_qty} of this item`
    )
    formik.setFieldValue(name, '')
    isValid = false
  }

  if (
    max_purchase_qty > 0 &&
    +input_value !== 0 &&
    +input_value > max_purchase_qty
  ) {
    toastMessage(
      'warning',
      `Sorry, you can only purchase ${max_purchase_qty} of this item`
    )
    formik.setFieldValue(name, max_purchase_qty)
    isValid = false
  }
  return isValid
}

export const scannerValidateInputBox = (
  input_value,
  min_purchase_qty,
  max_purchase_qty,
  available_inventory,
  toastMessage,
  name,
  formik,
  isIncrement
) => {
  let isValid = true

  if (input_value <= 0) {
    toastMessage('warning', `Sorry, you must purchase at least 1 of this item`)
    formik.setFieldValue(name, 1)
    isValid = false
  }
  if (input_value > available_inventory && isIncrement === 1) {
    toastMessage(
      'warning',
      `Sorry, you can purchase at maximum ${available_inventory} of this item`
    )
    formik.setFieldValue(name, available_inventory)
    isValid = false
  }
  if (
    min_purchase_qty > 0 &&
    +input_value !== 0 &&
    +input_value < min_purchase_qty &&
    isIncrement === -1
  ) {
    toastMessage(
      'warning',
      `Sorry, you must purchase at least ${min_purchase_qty} of this item`
    )
    formik.setFieldValue(name, min_purchase_qty)
    isValid = false
  }

  if (
    max_purchase_qty > 0 &&
    +input_value !== 0 &&
    +input_value > max_purchase_qty &&
    isIncrement === 1 &&
    isValid === true
  ) {
    toastMessage(
      'warning',
      `Sorry, you can only purchase ${max_purchase_qty} of this item`
    )
    formik.setFieldValue(name, max_purchase_qty)
    isValid = false
  }
  return isValid
}

export const initialValues = (variants, bigcommerce_id, min_purchase_qty) => {
  // Create dynamic intialvalues and validation schema ...
  const initialValues = {}

  if (variants?.['headings']?.length && variants?.['row_titles']?.length) {
    variants?.['variants']?.forEach((i) => {
      // Keep below .toString() and replace() function as it is if we directly put price value then...
      // decimal number are creating problem in set field value...

      const price = getMinimumPrice(
        i['price'],
        i['sale_price'],
        i['retail_price']
      ).toString()

      initialValues[
        `${bigcommerce_id},${i['id']},${i['sku']},${price.replace('.', '-')}`
      ] = ''
    })
  }

  if (variants?.headings?.length === 0 && variants?.row_titles?.length === 0) {
    if (variants['variants']?.length) {
      initialValues[
        `${bigcommerce_id},${variants['variants'][0]['id']},${variants['variants'][0]['sku']}`
      ] = min_purchase_qty || 1
    }
  }

  return initialValues
}
