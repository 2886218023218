import React, { Component } from 'react'
export default class IconDownload extends Component {
  render() {
    return (
      <svg
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        width="864"
        height="1024"
        viewBox="0 0 864 1024"
      >
        <title></title>
        <g id="icomoon-ignore"></g>
        <path
          fill="#000"
          d="M858.667 682.667v170.667c0 47.128-38.205 85.333-85.333 85.333h-682.667c-47.128 0-85.333-38.205-85.333-85.333v-170.667h85.333v170.667h682.667v-170.667h85.333zM474.667 536.994l140.497-140.497 60.34 60.34-243.503 243.503-243.503-243.503 60.34-60.34 140.497 140.497v-451.66h85.333v451.66z"
        ></path>
      </svg>
    )
  }
}
